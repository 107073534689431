import { Pipe, PipeTransform } from "@angular/core";
import { DecoStoreService } from "@app/stores/deco.store";
import { map, Observable } from "rxjs";

@Pipe({
    name: "institutionName",
    standalone: true,
})
export class InstitutionNamePipe implements PipeTransform {
    constructor(private decoStoreService: DecoStoreService) {}

    transform(value: any): Observable<string> {
        return this.decoStoreService
            .getInstitutions()
            .pipe(map((res) => res.find((x) => x.value === value)?.text || ""));
    }
}

@Pipe({
  name: "institutionType",
  standalone: true,
})
export class InstitutionTypePipe implements PipeTransform {
  constructor(private decoStoreService: DecoStoreService) {}

  transform(value: any): Observable<string> {
      return this.decoStoreService
          .getInstitutionTypes()
          .pipe(map((res) => res.find((x) => x.value === value)?.text || ""));
  }
}
